import React, { useState } from 'react';
import HeaderSettingModal from './HeaderSettingModal';
import '../assets/common.css';

const Header = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <header>
            <nav className="header navbar navbar-expand-lg justify-content-between">
                <div className="container">
                    <a className="navbar-brand" href='/'>LaunchCool</a>

                    <div className='parent-nav-icon'>
                        <a className="btn launchIdeabtn my-2 my-sm-0 marginRight10px" href='/p'>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <span>  查看文章</span>
                        </a>

                        <a className="btn loginbtn my-2 my-sm-0" href='/l'>
                            <li className='icon-login fa-regular fa-user'></li>
                            <span>登入</span>
                        </a>
                    </div>

                    <div className='parent-nav-icon-rwd' onClick={openModal}>
                        <span className="fa-solid fa-gear"></span>
                    </div>
                </div>
            </nav>

            {/* 把function傳進去 */}
            {isModalOpen && <HeaderSettingModal closeModal={closeModal} />}
        </header>
    );
};

export default Header;