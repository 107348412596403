// FirstPage.js
import React from 'react';
import '../../assets/createpost.css';

const CreateIdeaPostPage2 = ({ formData, updateFormData, nextPage }) => {
  let placeholderForAward = "Example : \n感謝初始用戶陪伴著LauchCool一同成長，你們的陪伴使我們更好，你們是LauchCool最重要的一份子。\nLaunchCool給初始用戶VIP的權限，可以無需花費任何費用就可以使用未來LaunchCool上提供的所有付費功能。";
  let placeholderForRecruit ="Example : ";

  const handleChange = (e) => {
    const { name, value } = e.target;
    alert(value);
    updateFormData({ [name]: value }); // 更新表单数据
  };

  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    updateFormData({[name]:e.target.checked}); // 更新狀態
  };

  return (
    <div className='content-main mb-5'>
      <div className="mb-3 btn">
        <div onClick={nextPage}>&lt; 上一步</div>
      </div>

      <div className="mb-5">
        <div className='post-create-item-subtitle'>初始用戶獎勵</div>
        <label className="post-create-item-title-describe">吸引新用戶使用的甜頭是什麼？可以設計一點好處給每一個用戶。（內容需填寫 100 字以上）</label>
        <textarea name='IntroduceGroup' className="textarea-custom" rows="9"
          placeholder={placeholderForAward} value={formData.IntroduceGroup || ''} onChange={handleChange}></textarea>
      </div>

      <div className="mb-5">
        <div className='post-create-item-subtitle'>聯絡資訊</div>
        <label className="post-create-item-title-describe">如果有人想與你聯絡，你願意嗎？留下你的聯絡方式。（內容最多20字）</label>
        <input name='IdeaName' type="text" className="input-custom" placeholder='信箱:xxxx@gmail.com' value={formData.IdeaName || ''} onChange={handleChange} />
      </div>

      <div className="mb-3 form-check">
        <input name='RecruitPartners' type="checkbox" className="form-check-input" checked={formData.RecruitPartners} onChange={handleCheckboxChange}/>
        <label className="form-check-label">是否要招募夥伴？（要招募請打勾，並填寫「我要招募」的內容。）</label>
      </div>

      {
        formData.RecruitPartners && <div className="mb-5">
          <div className='post-create-item-subtitle'>我要招募</div>
          <label className="post-create-item-title-describe">要招募什麼樣子的夥伴？ 說明負責角色 / 內容 / 合作方式 / 如何分潤（內容需填寫 100 字以上）</label>
          <textarea name='IntroduceGroup' className="textarea-custom" rows="9"
          placeholder={placeholderForRecruit} value={formData.IntroduceGroup || ''} onChange={handleChange}></textarea>
        </div>
      }

      <div className="d-flex justify-content-center">
        <a href="#" className="btn btnSendCreatePost">確認送出</a>
      </div>
    </div>
  );
}

export default CreateIdeaPostPage2;
