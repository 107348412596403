import React from 'react';

const PostList = ({ posts }) => {
    let idFromAPI = 1;
    return (
        <div className='account-list-div'>
            <a className='account-link-list' href={`/p?id=${idFromAPI}`}>
                <div className='post-profile-link-div'>
                    <div className=''>
                        <div className='account-profile-list-name'>Sharers</div>
                        <div className='post-name-list-subtitle mb-2'>一進去會先筆試 30分，接著由一個主管帶著資深工程師一起來面，簡單自我介紹
                    ，開始針對剛剛筆試結果討論，接著問之前寫的專案遇到什麼問題怎麼解決，
                    以及之前工作經歷，再來一些常見面試題目，最後談，整體來說算是愉快。</div>
                    </div>
                </div>

                <div className="hearts">
                    <span className="heart red">♥</span>
                    <span className="heart red">♥</span>
                    <span className="heart red">♥</span>
                    <span className="heart black">♥</span>
                    <span className="heart black">♥</span>
                    <span className='heartChar'>(1234)</span>
                </div>
            </a>
        </div>
    );
};

export default PostList;