import React, { useEffect, useState } from 'react';
import { FormProvider } from '../store/FormProvider';
import { useNavigate,useLocation } from 'react-router-dom';
import CreateIdeaPostPage1 from '../components/CreateIdeaPost/CreateIdeaPostPage1';
import CreateIdeaPostPage2 from '../components/CreateIdeaPost/CreateIdeaPostPage2';


const CreatePost = () => {
    const [page, setPage] = useState(1);
    const [formData,setFormData] = useState({
        'IdeaName':'',
        'SentenceDescribe':'',
        'ThreeFeatures1':'',
        'ThreeFeatures2':'',
        'ThreeFeatures3':'',
        'IntroduceGroup':'',
        'RecruitPartners':false
    });

    // 更新表单数据
    const updateFormData = (data) => {
        setFormData((prevFormData) => ({ ...prevFormData, ...data }));
    };
    
    const location = useLocation();

    // 取得查詢字串
    const queryParams = new URLSearchParams(location.search);
    const p = queryParams.get('p');

    const page1 = () => setPage(1);
    const page2 = () => setPage(2);
    
    return (
        <div className="container content-wrapper">
            <div className="row">
                <div className="col-lg-8">
                {
                    page === 2 ? (<CreateIdeaPostPage2 formData={formData} updateFormData={updateFormData} nextPage={page1} />): 
                    (<CreateIdeaPostPage1 formData={formData} updateFormData={updateFormData} nextPage={page2} />)
                }
                </div>
            </div>
        </div>
    );
};

export default CreatePost;