import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/account.css';
import '../../assets/common.css';

const AccountDetail = () => {
  return (
    <div className="mb-5 content-main">
    <div className='row'>
        
        <div className='post-profile-link-div col-lg-3'>
            {false ?
            <img src="https://via.placeholder.com/40" className="account-profile-image" />
            :
            <div className='account-profile-image-FirstName'>J</div>}
        </div>
        <div className='col-lg-9'>
        <div className='mb-3'>
            <span className='account-profile-name'>Jim Liu</span>
            <span className='account-profile-name-subtitle'>輔仁大學 Fu Jen Catholic University, 學士學位, 電機工程學系, 2015 ~ 2019</span>
        </div>
        <p className='postContentColor'>
            大家好我是Jim，是ASP.NET Core工程師，開發過區塊鏈錢包 API Server、VPN API Server，可以獨立開發設計一整個系統，熱愛寫程式平常會寫Side Project熱衷於產品開發，今年出版一本書「ASP.NET Core 6 零基礎建立自己的部落格」。
        </p>
            <div className='profileAdditioinInfoIcon'>
            <span className="fa-brands fa-github"></span>
            <span className="fa-brands fa-line marginLeft10px"></span>
            <span className="fa-brands fa-instagram marginLeft10px"></span>
            <span className="fa-solid fa-envelope marginLeft10px"></span>
            </div>
        </div>
    </div>

    <h6 className="boldtext mb-3 mt-3">專業技能</h6>
    <p>
        1. 架構設計
        2. .NET、React JS
        3. Docker、Jenkins
        4. MySQL、MsSQL
        5. SEO 
        6. Sui Move 
        7. Solidity Foundry
    </p>
    <h6 className="boldtext mb-3 mt-3">工作經歷</h6>
    <h6 className="boldtext mb-3 mt-3">學歷</h6>
    </div>
  );
};

export default AccountDetail;