import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Post from './pages/Post';
import CreatePost from './pages/CreatePost';
import Account from './pages/Account';
import Login from './pages/Login';
import Register from './pages/Register';
import ProtectedRoute from './utils/ProtectedRoute';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/p" element={<Post />} />
          <Route path="/cp" element={
            // <ProtectedRoute>
              <CreatePost />
            // </ProtectedRoute>
          } />
          <Route path="/a" element={<Account />} />
          <Route path="/l" element={<Login />} />
          <Route path="/r" element={<Register />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
