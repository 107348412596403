import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import AccountBrief from '../components/Account/AccountBrief';
import AccountDetail from '../components/Account/AccountDetail';
import PostList from '../components/Post/SidePostList';
import '../assets/account.css';
import '../assets/common.css';

const Account = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  let Component;
    if (id !== '' && id !== null) {
        Component = AccountDetail;
    } else {
        Component = AccountBrief;
    }

  return (
    <div className="container content-wrapper">
      <div className="row">
        <div className="col-lg-8">
          <Component/>
        </div>

        <div className='col-lg-4'>
          <PostList />
        </div>
      </div>
    </div>
  );
};

export default Account;