import React, { useEffect, useState } from 'react';
import { getTest } from '../api/api';
import '../assets/home.css';
import '../assets/home-rwd.css';

const Home = () => {
    // store variable value and define a function called setData.
    const [data, setData] = useState([]);

    // enter this page and mount will trigger this function.
    // dependency is empty array, so it will only trigger once.
    useEffect(() => {
        getTest()
            .then(data => setData(data))
            .catch(error => console.log(error));
    }, []);

    return (
        <div className="hero-section">
            <div className="container">
                <div className="homeTitle">發起你的酷點子！</div>
                <div className="homeSubTitle">創業第一站，有好點子還要好夥伴，來LaunchCool找吧</div>

                <div className="homeActionBtn">
                    <a href="/a" className="btn btn-cta">
                        <i className="fa-solid fa-users"></i>
                        <span> 尋找夥伴</span>
                    </a>
                    <a href="/cp" className="btn btn-cta-secondary">
                        <i className="icon-lightbulb fa-regular fa-lightbulb"></i>
                        <span>  發起點子</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Home;