import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { loginApiAsync } from '../api/api';
import { setAuthenticated } from '../utils/Auth';
import '../assets/common.css';
import '../assets/common-rwd.css';

const Login = () => {
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        Email: '',
        Password: ''
    });

    const handleInputChange = (e) => {
        console.log(e.target);
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    };

    const Login = async () => {
        let token = await loginApiAsync(loginData);
        setAuthenticated(token);
        navigate('/');
    };

    const RedirectToRegister = async () => {
        navigate('/r');
    };

    return (
        <div className="container content-wrapper">
            <div className="mb-5 content-main-Login">
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        <h3>登入</h3>
                    </div>
                    <div className="btn" onClick={RedirectToRegister}>註冊</div>
                </div>

                <div>
                    <div className="mb-3">
                        <label className="form-label">帳號</label>
                        <input name='Email' type="text" className="form-control" value={loginData.Email || ''} onChange={handleInputChange} />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">密碼</label>
                        <input name='Password' type="text" className="form-control" value={loginData.Password || ''} onChange={handleInputChange} />
                    </div>
                </div>

                <div className="mb-3 d-flex justify-content-end">
                    <button type="text" className="btn" onClick={Login}>登入</button>
                </div>

            </div>
        </div>
    );
}

export default Login;