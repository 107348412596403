import React from 'react';
import '../assets/Modal.css';

const HeaderSettingModal = ({ closeModal }) => {
    return (
        <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="close" onClick={closeModal}>
                    &times;
                </div>
                
                <a className="btn launchIdeabtn my-2 my-sm-0 mt-4" href='/p'>
                <i className="icon-lightbulb fa-solid fa-magnifying-glass"></i>
                    <span> 查看文章</span>
                </a>

                <a className="btn loginbtn my-2 my-sm-0" href='/l'>
                    <li className='icon-login fa-regular fa-user'></li>
                    <span>登入</span>
                </a>
            </div>
        </div>
    );
};

export default HeaderSettingModal;
