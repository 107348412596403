const authTokenKey = "jwtToken";

export const isAuthenticated = () => {
    let result = false;
    let jwtToken = getToken();

    if (jwtToken) {
        console.log("jwtToken: ", jwtToken);
        result = true;
    }

    return result;
}

export const setAuthenticated = (token) => {
    localStorage.setItem(authTokenKey, token);

    // 解析 token 以获取过期时间
    const payload = JSON.parse(atob(token.split('.')[1]));
    console.log(payload);
    const expiration = payload.exp * 1000; // `exp` 是以秒为单位的，将其转换为毫秒
    
    localStorage.setItem("jwtExpiration", expiration);
}

export const removeAuthToken = () => {
    localStorage.removeItem(authTokenKey);
}

export const isTokenExpired = () => {
    const expiration = localStorage.getItem("jwtExpiration");

    if (!expiration) return true;

    const now = Date.now();
    return now > expiration;
};

export const getToken = () => {
    if (isTokenExpired()) {
        localStorage.removeItem(authTokenKey);
        localStorage.removeItem("jwtExpiration");
        return null;
    }
    return localStorage.getItem(authTokenKey);
};