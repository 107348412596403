import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountBriefSideListSub from './AccountBriefSideListSub';
import '../../assets/account.css';
import '../../assets/common.css';

const AccountBriefSideList = () => {
    let idFromAPI = 1;
    return (
        <div className='mb-5 content-main'>
            <div>
                <h4 className='mb-4'>推薦帳號</h4>
            </div>

            <AccountBriefSideListSub />

            <AccountBriefSideListSub />

            <AccountBriefSideListSub />

            <AccountBriefSideListSub />
        </div>
    );
};

export default AccountBriefSideList;