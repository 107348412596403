import axios from 'axios';

// const API_BASE_URL = 'http://localhost:5268/api';
const API_BASE_URL = 'https://mulproject-cpgydpd6dfdtf0ch.eastasia-01.azurewebsites.net/api';

// 取得使用者資料
export const getTest = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/GetWeatherForecast`);
    return response.data; // 返回資料
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const loginApiAsync = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/login`,data);
    
    return response.data; // 返回資料
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const registerApiAsync = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/register`,data);
    
    return response.data; // 返回資料
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};