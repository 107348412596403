import React, { useEffect, useState } from 'react';
import { getTest } from '../../api/api';
import { useNavigate } from 'react-router-dom';
import '../../assets/post.css';
import '../../assets/common.css';

const PostIdeaBrief = () => {
    // store variable value and define a function called setData.
    const [data, setData] = useState([]);
    const [isClassActive, setIsExpanded] = useState(true);
    const navigate = useNavigate();

    // enter this page and mount will trigger this function.
    // dependency is empty array, so it will only trigger once.
    useEffect(() => {
        getTest()
            .then(data => setData(data))
            .catch(error => console.log(error));
    }, []);

    const handleProfileClick = () => {
        // 可以在這裡執行其他操作，例如記錄點擊事件
        navigate(`/a?id=1`);
    };
    let idFromAPI = 1;
    return (
        <div>
            <div className="mb-5 content-main">
                <div className='post-profile-link-div'>
                    <a className='remove-link-css' href='/a?id=1'>
                        <div>
                            {false ? 
                            <img src="https://via.placeholder.com/40" className="post-profile-image"/> 
                            : 
                            <span className='post-profile-image-FirstName'>J</span>}
                            <span className='post-profile-name'>Jim Liu</span>
                        </div>
                    </a>
                </div>
                <a className='remove-link-css' href={`/p?id=${idFromAPI}`}>
                    <h1>Sharers</h1>
                    <div className="hearts">
                        <span className="heart red">♥</span>
                        <span className="heart red">♥</span>
                        <span className="heart red">♥</span>
                        <span className="heart black">♥</span>
                        <span className="heart black">♥</span>
                        <span className='heartChar'>(1234)</span>
                        <span className="heartChar">新北 · 2021.06.30</span>
                        <span className="heartChar">＃爭夥伴！</span>
                    </div>

                    <h6 className="boldtext mb-3 mt-3">創意內容</h6>
                    <p className='postContentColor'>一進去會先筆試 30分，接著由一個主管帶著資深工程師一起來面，簡單自我介紹
                        ，開始針對剛剛筆試結果討論，接著問之前寫的專案遇到什麼問題怎麼解決，
                        以及之前工作經歷，再來一些常見面試題目，最後談到薪資福利，整體來說算是愉快。 more...
                    </p>
                </a>
                <hr />
                <div className="d-flex justify-content-between">
                    <div>
                        <span className="shareIcon fa-regular fa-heart"></span>
                        <span className="fa-solid fa-share shareIcon"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostIdeaBrief;