import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { registerApiAsync } from '../api/api';
import '../assets/common.css';
import '../assets/common-rwd.css';

const Register = () => {
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        Email: '',
        Password: ''
    });

    const handleInputChange = (e) => {
        console.log(e.target);
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    };

    const Register = async () => {
        await registerApiAsync(loginData);

        // 導去某一頁，告知需要去收驗證信
        navigate('/');
    };

    const RedirectToLogin = async () => {
        navigate('/l');
    };

    return (
        <div className="container content-wrapper">
            <div className="mb-5 content-main-Login">
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        <h3>註冊</h3>
                    </div>
                    <div className="btn" onClick={RedirectToLogin}>登入</div>
                </div>

                <div className="mb-3">
                    <div className="mb-3">
                        <label className="form-label">帳號</label>
                        <input name='Email' type="text" className="form-control" value={loginData.Email || ''} onChange={handleInputChange} />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">密碼</label>
                        <input name='Password' type="text" className="form-control" value={loginData.Password || ''} onChange={handleInputChange} />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">確認密碼</label>
                        <input name='Password' type="text" className="form-control" value={loginData.Password || ''} onChange={handleInputChange} />
                    </div>
                </div>

                <div className="mb-3 d-flex justify-content-end">
                    <button type="text" className="btn" onClick={Register}>註冊</button>
                </div>

            </div>
        </div>
    );
}

export default Register;